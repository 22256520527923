<template>
  <div>
    <div class="videoWrapper">
      <iframe width="100%" :src="`https://www.youtube.com/embed/${ytSong.id.videoId}/?autoplay=0&rel=0&modestbranding=1`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <v-btn :disabled="submitting" :loading="submitting" color="red" block class="mt-4" @click="addCover">
      <span v-show="!submitting">Submit to Database</span>
      <span v-show="submitting">Submitting</span>
    </v-btn>
  </div>
</template>

<script>
import { postCover } from '../../../services'
export default {
  props: {
    ytSong: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      submitting: false
    }
  },
  methods: {
    async addCover () {
      try {
        this.submitting = true
        const url = `https://www.youtube.com/watch?v=${this.ytSong.id.videoId}`
        await postCover(this.$route.params.pid, url)
        this.$emit('fetchCovers')
      } catch (e) {
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped>

</style>
