<template>
  <div>
    <div class="mb-5">
      <v-sheet class="pa-3 rounded-xl" v-if="ostLoading">
        <v-row>
          <v-col :cols="3">
            <v-skeleton-loader
              class="rounded-xl"
              type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="4" class="d-none d-sm-block">
            <v-skeleton-loader
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>
      <v-card v-if="track" class="mx-auto rounded-xl" width="100%">
        <v-img gradient="to right, rgba(0,0,0,.9), rgba(0,0,0,.7)" class="white--text align-end rounded-xl" height="230" :src="track.spotify.images[0].url">
          <div class="song-details d-flex">
            <div class="rounded-xl img-container">
              <v-img max-width="190" height="190" class="rounded-xl img d-none d-sm-block" :src="track.spotify.images[0].url"></v-img>
            </div>
            <div class="ml-4 details d-flex flex-column justify-center">
              <h1>{{ track.spotify.name }} Cover Songs</h1>
              <h2>{{ track.spotify.album }}</h2>
              <h3 class="mb-5">By {{ track.spotify.artists.join(', ') }}</h3>
              <add-cover-modal @fetchCovers="fetchCovers"/>
              <v-btn color="red" dark class="align-self-start mt-3" @click="fetchYouTubeCovers" :loading="fetchLoading" :disabled="youTubeCovers.length > 0">
                <v-icon class="mr-3">mdi-youtube</v-icon>Fetch Covers
              </v-btn>
            </div>
          </div>
        </v-img>
      </v-card>
    </div>
    <v-row v-if="coversLoading">
      <v-col :lg="3" :md="4" :cols="6" v-for="i in 8" :key="i.id" class="mb-5">
        <v-skeleton-loader
          class="rounded-xl"
          type="image"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="rounded-xl mt-3"
          type="table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="youTubeCovers.length > 0">
      <v-col :lg="3" :md="4" :sm="6" :cols="12" v-for="video in youTubeCovers" :key="video.id.videoId">
        <yt-iframe :ytSong="video" @fetchCovers="fetchCovers" isAdmin />
      </v-col>
    </v-row>
    <hr class="my-4" v-if="youTubeCovers.length > 0">
    <v-row>
      <v-col :lg="3" :md="4" :cols="6" v-for="track in songs" :key="track.id">
        <cover-track :track="track" v-if="track" @fetchCovers="fetchCovers" isAdmin/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CoverTrack from '../../../components/Admin/CoverTrack'
import AddCoverModal from '../../CoversComponents/AddCoverModal'
import ytIframe from './ytIframe'

export default {
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      songs: [],
      error: false,
      track: null,
      limit: 18,
      ostLoading: true,
      coversLoading: true,
      youTubeCovers: [],
      fetchLoading: false
    }
  },
  async mounted () {
    await this.fetchCovers()
    await this.fetchOST()
  },
  methods: {
    async fetchOST () {
      try {
        const track = await this.axios.get(`/api/v1/tracks/${this.$route.params.pid}`)
        this.track = track.data.data.song
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.ostLoading = false
      }
    },
    async fetchCovers () {
      try {
        this.loading = true
        const songData = await this.axios.get(`/api/v1/covers/${this.$route.params.pid}`, {
          params: {
            page: this.page
          }
        })
        this.$emit('paginationLength', Math.ceil(songData.data.total / this.limit))
        this.songs = songData.data.data.songs
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.coversLoading = false
      }
    },
    async fetchYouTubeCovers () {
      try {
        this.fetchLoading = true
        const ytData = await this.axios.post('/api/v1/yt/songs/', {
          query: `${this.track.spotify.name} cover songs`
        })
        this.youTubeCovers = ytData.data.videos.items
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.fetchLoading = false
      }
    }
  },
  components: {
    CoverTrack,
    AddCoverModal,
    ytIframe
  }
}
</script>
<style lang="scss" scoped>
  .song-details {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    .details {
      font-size: 0.75rem !important;
    }
    .img-container {
      .img {
        z-index: -1;
      }
    }
  }
</style>
