<template>
  <div>
<!--    <v-card :loading="loading" :to="{name: 'SingleCoverSong', params: {slug: track.slug}}" flat>-->
<!--      <v-img :src="track.yt.thumbnails[0].high.url" class="white&#45;&#45;text align-end pointer" width="560" height="315"></v-img>-->
<!--      <v-card-subtitle>{{ track.artists[0].ytChannel.title }} </v-card-subtitle>-->
<!--    </v-card>-->
    <div v-if="isList">
      <v-card elevation="0" :loading="loading" class="mx-auto" :to="{name: 'SingleCoverSong', params: {slug: track.slug}}">
        <v-list dense elevation="0">
          <v-list-item dense>
            <v-list-item-content class="pa-0">
              <v-list-item-title class="break-text d-flex justify-space-between align-center">
                <div class="d-flex align-center text-wrap mr-10">
                  <div class="pr-4">
                    <v-img gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :src="track.artists[0].ytChannel.thumbnails[0].default.url" class="white--text align-end" height="40" width="40"></v-img>
                  </div>
                  <div>{{ track.yt.title }}</div>
                </div>
                <div>{{ track.artists[0].ytChannel.title }}</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </div>
    <div v-else>
      <v-card class="mr-0 rounded-xl" flat max-width="100%" :to="{name: 'SingleCoverSong', params: {slug: track.slug}}" elevation="0">
        <v-img class="white--text align-end" :src="track.yt.thumbnails[0].medium.url"></v-img>
      </v-card>
      <v-card flat color="transparent">
        <div class="avatar-container">
          <v-list-item-avatar tile class="rounded-circle mt-0 avatar" size="40">
            <router-link :to="{name: 'ArtistCovers', params: {slug: track.artists[0].slug }}"><v-img class="rounded-circle" :src="track.artists[0].ytChannel.thumbnails[0].default.url"></v-img></router-link>
          </v-list-item-avatar>
        </div>
        <v-card-title class="pl-13 pt-1 text-truncate">
          <h6 class="text-truncate">
            <router-link class="text-decoration-none" :to="{name: 'ArtistCovers', params: {slug: track.artists[0].slug }}">{{ track.artists[0].ytChannel.title }}</router-link>
          </h6>
        </v-card-title>
        <v-card-subtitle class="pl-13 mt-n5">
          {{ trackDate(track.yt.publishedAt) }}
        </v-card-subtitle>
        <v-card-actions v-if="isAdmin">
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click.prevent class="dots">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="featureCover">
                <v-list-item-title v-if="!track.featured"><v-icon small color="pink">mdi-star</v-icon> Featured</v-list-item-title>
                <v-list-item-title v-else><v-icon small color="black">mdi-star</v-icon> Remove Featured</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteCover">
                <v-list-item-title><v-icon small color="error">mdi-trash-can</v-icon> Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    track: {
      type: Object,
      default: () => {}
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      done: false,
      clicked: false,
      loading: false
    }
  },
  methods: {
    async deleteCover () {
      try {
        await this.axios.delete(`/api/v1/covers/${this.track._id}`)
        this.$emit('fetchCovers')
      } catch (e) {
        this.$snackbar.showMessage({ content: 'Cover songs is NOT deleted!' })
      }
    },
    async featureCover () {
      try {
        await this.axios.patch(`/api/v1/covers/${this.track._id}`, {
          featured: !this.track.featured
        })
        this.$emit('fetchCovers')
      } catch (e) {
        this.$snackbar.showMessage({ content: 'Cover songs is NOT deleted!' })
      }
    },
    trackDate (d) {
      const date = new Date(d)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      return `${da} ${mo} ${ye}`
    }
  }
}
</script>
<style lang="scss" scoped>
  .avatar-container {
    position: relative;
  }
  .avatar {
    position: absolute;
    top: 0.75rem;
  }
  .dots {
    position: absolute;
    right: 0;
    top: 1rem;
  }
</style>
