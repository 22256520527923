<template>
  <div>
    <div>
      <v-btn class="mb-10" depressed plain x-large block v-if="!isSpotifyConnected" :loading="loading" :disabled="loading" color="success" @click="connectSpotify">
        <template v-slot:loader>
          <span>Connecting...</span>
        </template>
        <v-icon>mdi-spotify</v-icon> Connect To Spotify
      </v-btn>
      <v-form @submit.prevent="fetchSpotifySongs" class="d-flex relative" v-else>
        <v-text-field
          v-model="query"
          :loading="loading"
          :disabled="loading"
          outlined
          flat
          rounded
          :rules="[required]"
          color="success"
          solo
          label="Search Spotify"
          prepend-inner-icon="mdi-spotify"
          single-line
        ></v-text-field>
        <v-btn class="search-btn pt-0 absolute right" depressed plain
               fab color="success" @click="fetchSpotifySongs"><v-icon>mdi-magnify</v-icon></v-btn>
      </v-form>
    </div>
    <v-row>
      <v-col :lg="3" v-for="track in spotifySongs" :key="track.id">
        <spotify-song :track="track" v-if="track"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpotifySong from '../../components/Admin/SpotifySong'
// import { spotifySongsDummy } from '../../assets/data/spotify'
import { spotifyConnectFn } from '../../helpers/authentication'

export default {
  data () {
    return {
      query: '',
      spotifySongs: null,
      loading: false,
      required: value => !!value || 'This field cannot be empty.'
    }
  },
  computed: {
    isSpotifyConnected () {
      return this.$store.state.spotifyToken
    },
    spotifyTimer () {
      return new Date(this.$store.state.spotifyTimer)
    }
  },
  methods: {
    async fetchSpotifySongs () {
      try {
        this.loading = true
        const isTimeLeft = Date.now() < this.spotifyTimer
        if (this.query && isTimeLeft) {
          const res = await this.axios.post('/api/v1/spotify/songs/', {
            query: this.query
          })
          this.spotifySongs = res.data.songs.tracks.items
        } else if (!isTimeLeft) {
          await this.$store.dispatch('setSpotifyAuth', {
            token: null,
            timer: null
          })
        }
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    async connectSpotify () {
      try {
        this.loading = true
        const res = await spotifyConnectFn()
        await this.$store.dispatch('setSpotifyAuth', {
          token: res.data.spotifyToken,
          timer: new Date(Date.now() + 60 * 59 * 1000)
        })
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    }
  },
  components: { SpotifySong }
}
</script>
<style scoped lang="scss">
  .search-btn {
    box-sizing: content-box;
  }
</style>
