<template>
  <div class="admin-tracks-list">
    <!--    {{ spotifySongs && spotifySongs[4].artists.map(artist => artist.name) }}-->
    <div v-if="covers.length > 0">
      <h3 class="mb-5">Featured Covers (Should be only 1)</h3>
      <covers-listing
        :page="page"
        is-list
        :tracks="covers"
        :limit="limit"
        :isAdmin="false"
        class="mb-10"
        :loading="coversLoading" />
    </div>
    <h3 class="mb-5">Latest Songs Added</h3>
    <track-listing
      :key="page"
      isAdmin
      :page="page"
      :songs="songs"
      :limit="limit"
      :loading="loading" />
    <v-pagination
      class="mt-5"
      v-model="page"
      v-if="paginationLength > 1"
      :length="paginationLength"
    ></v-pagination>
  </div>
</template>

<script>
import TrackListing from './List'
import CoversListing from '../../../components/CoversComponents/List'
export default {
  props: {
    view: {
      type: String,
      default: 'dashboard'
    }
  },
  data () {
    return {
      loading: true,
      songs: [],
      covers: [],
      limit: 18,
      total: 0,
      coversLoading: false
    }
  },
  async mounted () {
    await this.fetchSongs()
    await this.fetchFeaturedCovers()
  },
  computed: {
    page: {
      get () {
        return parseInt(this.$route.params.page) || 1
      },
      set (newValue) {
        this.$router.push({
          name: this.view === 'dashboard' ? 'AdminDashboard' : 'TracksListAdmin',
          params: {
            page: newValue
          }
        })
      }
    },
    paginationLength () {
      return Math.ceil(this.total / this.limit)
    }
  },
  methods: {
    async fetchSongs () {
      try {
        this.loading = true
        const songData = await this.axios.get('/api/v1/tracks/', {
          params: {
            page: this.page
          }
        })
        this.songs = songData.data.data.songs
        this.total = songData.data.total
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    async fetchFeaturedCovers () {
      try {
        this.loading = true
        const songData = await this.axios.get('/api/v1/covers/featured/')
        this.covers = songData.data.data.covers
        this.total = songData.data.total
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    '$route.params.page' () {
      this.fetchSongs()
    }
  },
  components: {
    TrackListing,
    CoversListing
  }
}
</script>
