<template>
  <div>
    <div v-if="isAdminDashboard">
      <v-row v-if="loading" no-gutters>
        <v-col :cols="12" v-for="i in limit" :key="i.id">
          <v-skeleton-loader
            elevation="0"
            v-bind="attrs"
            type="list-item"
          ></v-skeleton-loader>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-else no-gutters>
        <v-col :cols="12" v-for="track in songs" :key="track.id">
          <single-track-list-item :track="track" v-if="track"/>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row v-if="loading">
        <v-col :lg="2" :cols="6" :md="3" :sm="4" :xl="2" v-for="i in limit" :key="i.id">
          <v-skeleton-loader
            elevation="0"
            class="rounded-xl"
            v-bind="attrs"
            type="image, list-item"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col :lg="2" :cols="6" :md="3" :sm="4" :xl="2" v-for="track in songs" :key="track.id">
          <single-track-card :track="track" v-if="track" :is-admin="isAdmin"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import SingleTrackCard from './SingleTrackCard'
import SingleTrackListItem from './SingleTrackListItem'

export default {
  props: {
    page: {
      type: Number,
      default: 1
    },
    songs: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 18
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attrs: {
        elevation: 1
      }
    }
  },
  computed: {
    isAdminDashboard () {
      return this.$route.name === 'AdminDashboard'
    }
  },
  components: {
    SingleTrackCard,
    SingleTrackListItem
  }
}
</script>

<style scoped>

</style>
