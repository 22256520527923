<template>
  <div>
    <div v-if="!isList">
      <v-row v-if="loading">
        <v-col :lg="3" :md="4" :cols="6" v-for="i in 8" :key="i.id" class="mb-5">
          <v-skeleton-loader
            class="rounded-xl"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded-xl mt-3"
            type="table-tfoot"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col :lg="3" :cols="6" :md="4" v-for="track in tracks" :key="track.id">
          <cover-track :track="track" v-if="track" :isAdmin="isAdmin" :is-list="isList"/>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row v-if="loading">
        <v-col :cols="12" v-for="i in 2" :key="i.id" class="mb-5">
          <v-skeleton-loader
            class="rounded-xl"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded-xl mt-3"
            type="table-tfoot"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col :cols="12" v-for="track in tracks" :key="track.id" class="py-0">
          <cover-track :track="track" v-if="track" :isAdmin="isAdmin" :is-list="isList"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CoverTrack from '../../components/Admin/CoverTrack'
export default {
  props: {
    tracks: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attrs: {
        elevation: 1
      }
    }
  },
  components: {
    CoverTrack
  }
}
</script>

<style scoped>

</style>
