<template>
  <v-card :loading="loading" class="mx-auto" max-width="374">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
    </template>
    <v-img gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)" :src="track.album.images[1].url" class="white--text align-end">
      <div class="d-flex justify-space-between px-3 py-2 font-weight-bold text-sm-body-2">
        <span>{{ track.album.release_date | moment }}</span>
        <span>{{ duration(track.duration_ms) }}</span>
      </div>
    </v-img>

    <v-card-text>
      <v-row align="center" class="mx-0 mt-3">
        <div class="font-weight-bold text-truncate">Album {{  track.album.name }}</div>
      </v-row>
    </v-card-text>
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="break-text">{{ track.name }}</v-list-item-title>
        <v-list-item-subtitle><span class="mr-2" v-for="artist in track.artists" :key="artist.id">{{ artist.name }}</span></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions class="py-4">
      <v-btn color="secondary" @click="addOriginalSong" block large v-if="!done">
        <span>Add Song</span>
      </v-btn>
      <v-btn v-else color="success" block large disabled outlined depressed>
        <span>Song Added!</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { duration } from '../../utils/time'
export default {
  props: {
    track: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      done: false,
      loading: false
    }
  },
  methods: {
    async addOriginalSong () {
      try {
        const songObj = {
          id: this.track.id,
          name: this.track.name,
          images: this.track.album.images,
          album: this.track.album.name,
          duration: this.track.duration_ms,
          artists: this.track.artists.map(artist => artist.name),
          releaseDate: this.track.album.release_date
        }
        const songData = await this.axios.post('/api/v1/tracks/', songObj)
        if (songData) {
          this.done = true
        }
        await this.$router.push({
          name: 'CoversListAdmin',
          params: {
            pid: songData.data.data.newSong._id
          }
        })
      } catch (e) {
        this.showSnackbar(e.response.data.message)
      }
    },
    showSnackbar (content) {
      this.$snackbar.showMessage({ content })
    },
    duration () {
      return duration(this.track.duration_ms)
    }
  }
}
</script>

<style scoped>

</style>
