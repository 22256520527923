<template>
  <div class="admin-tracks-list">
    <!--    {{ spotifySongs && spotifySongs[4].artists.map(artist => artist.name) }}-->
    <cover-list-components
      :key="$route.params.pid"
      :page="page"
      @paginationLength="handlePaginationLength"/>
    <v-pagination
      class="mt-5"
      v-model="page"
      :length="paginationLength"
      v-if="paginationLength > 1"
    ></v-pagination>
  </div>
</template>

<script>
import CoverListComponents from '../../components/Admin/CoverListComponents'
export default {
  data () {
    return {
      paginationLength: null
    }
  },
  computed: {
    page: {
      get () {
        return parseInt(this.$route.params.page) || 1
      },
      set (newValue) {
        this.$router.push({
          name: 'CoversListAdmin',
          params: {
            page: newValue
          }
        })
      }
    }
  },
  methods: {
    handlePaginationLength (value) {
      this.paginationLength = value
    }
  },
  components: {
    CoverListComponents
  }
}
</script>
