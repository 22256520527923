<template>
  <div class="admin-tracks-list">
    <common view="dashboard"/>
  </div>
</template>

<script>
import Common from '../../components/Admin/TrackListComponents/Common'
export default {
  components: {
    Common
  }
}
</script>
