<template>
  <v-card elevation="0" :loading="loading" class="mx-auto" :to="{name: 'CoversListAdmin',params: { pid: track._id }}">
    <v-list dense elevation="0">
      <v-list-item dense>
        <v-list-item-content class="pa-0">
          <v-list-item-title class="break-text d-flex justify-space-between align-center">
            <div class="d-flex align-center text-wrap mr-10">
              <div class="pr-4">
                <v-img gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :src="track.spotify.images[1].url" class="white--text align-end" height="40" width="40"></v-img>
              </div>
              <div>{{ track.spotify.name }}</div>
            </div>
            <div>{{ trackDate }}</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import { duration } from '../../../utils/time'
export default {
  props: {
    track: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      done: false,
      loading: false
    }
  },
  computed: {
    trackDate () {
      const date = new Date(this.track.createdAt)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      return `${da} ${mo} ${ye}`
    }
  },
  methods: {
    duration (d) {
      return duration(d)
    }
  }
}
</script>
