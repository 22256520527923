<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" dark v-bind="attrs" v-on="on" class="align-self-start">
          <v-icon>mdi-plus</v-icon>Submit Cover
        </v-btn>
      </template>
      <v-card class="mx-auto">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              SUBMIT A COVER SONG
            </div>
            <v-list-item-title class="headline mb-1">
              YouTube Link
            </v-list-item-title>
            <v-list-item-subtitle>Submit a Youtube Video Cover.</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar tile size="80" color="white">
            <v-icon size="100" x-large color="red">mdi-youtube</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field persistent-hint hint="URL is required!" :rules="urlRules" v-model="url" label="Youtube URL" outlined required></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-btn x-large block color="secondary" @click="addCover" :disabled="submitting">Add a new cover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { postCover } from '../../services'
export default {
  data () {
    return {
      dialog: false,
      submitting: false,
      url: null,
      urlRules: [
        v => !!v || 'YouTube URL is required'
      ]
    }
  },
  methods: {
    async addCover () {
      try {
        this.submitting = true
        if (this.url) {
          await postCover(this.$route.params.pid, this.url)
          this.url = null
          this.dialog = false
          this.$emit('fetchCovers')
        }
      } catch (e) {
      } finally {
        this.loading = false
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped>

</style>
