import axios from '../utils/axios'

export const fetchSingleCover = async (slug) => {
  return axios.get(`/api/v1/covers/p/${slug}`)
}

export const fetchCoversByOST = async (pid, id) => {
  return id ? axios.get(`/api/v1/covers/${pid}/${id}`) : axios.get(`/api/v1/covers/${pid}`)
}

export const postCover = async (pid, url) => {
  return axios.post(`/api/v1/covers/${pid}`, {
    url
  })
}
